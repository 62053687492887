<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Which of the resonance structures shown below would be the most stable?</p>

      <p class="pl-6 mb-6">
        <v-img style="max-width: 440px" :src="imageHeadName" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'chemUci51LC_Task85',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'Structure A',
          value: 'a',
        },
        {
          text: 'Structure B',
          value: 'b',
        },
        {
          text: 'Structure C',
          value: 'c',
        },
        {
          text: 'Structure D',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    imageHeadName() {
      return '/img/assignments/task85_main.png';
    },
  },
};
</script>
